import { useState } from "react";

function App() {
  const [listItem, setListItem] = useState(null)
  const [itemArray, setItemAdday] = useState([])
  const itemsArray = []

  const addToArray = (item) => {
    itemArray.push(item)
    console.log(itemArray)
    return itemArray
  }

  return (
    <>
      <div className="App">
        <h1>Check It</h1>
        <input onChange={(ev) => setListItem(ev.target.value)}></input>
        <button onClick={() => addToArray(listItem)}>Add</button>
      </div>
      <div>
        {itemsArray.length > 0 ? itemsArray.map(item => { return <p>{item}</p>}) : "Nothing to display."}
      </div>
      <div>
        {itemArray.length > 0 ? itemsArray.map(item => { return <p>{item}</p> }) : "Nothing to display."}
      </div>
    </>
  );
}

export default App;
